@import "../../../../../styles/mixins";
.wrapper {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 4px;

  @include scrollBar;

  img  {
    border-radius: 4px;
    flex-shrink: 0;
    cursor: pointer;
  }
}
