.itemWrapper {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.auto {
  margin-left: auto;
}


.box {
  display: flex;
  padding: 12px 0;
  gap: 8px;
  align-items: center;
}

.relative {
  width: 100%;
  height: 100%;
  position: relative;
}

.noImage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }
}

.column {
  display: flex;
  flex-direction: column!important;
  gap: 12px;
  position: relative;
  flex: 0 0 19%;

  &.maxW {
    flex: 0 0 168px;
    margin-left: auto;
  }
}

.row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
}

.divider {
  position: absolute;
  left: 16px;
  top: 20px;
  bottom: -12px;
  display: flex;
  height: auto!important;
}

.adres {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.copy {
  width: 16px;
  height: 16px;
  padding: 0;
  margin-left: 4px;
}
