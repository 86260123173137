.areaWrapper {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  textarea {
    min-height: 38px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: transparent;
    width: 100%;
    padding: 8px;
    border: none;
    resize: none;
    outline: none;
    color: inherit;
    &:focus,
    &:active{
      background: transparent;
      border: none;
      resize: none;
    }
  }
}
