@import "../../../../styles/mixins";

.scroll {
  @include scrollBar;
}

.dialogWrapper {

  form {
    width: 332px;
    padding: 24px;
    border-radius: 12px;
    overflow: hidden;
  }

  :global {
    .MuiPaper-rounded {
      border-radius: 12px!important;
    }
  }
}

.btns {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.comment {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  :global {
    .MuiCheckbox-root {
      padding: 0!important;
      margin-right: 12px;
    }
  }
}

.checkbox {
  display: flex;
  padding: 0;
  margin-right: 12px;
  margin-left: -3px;
}

.hidden {
  display: none;
}


.timeWrapper {
  :global {
    .MuiInputBase-root {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      min-height: 40px;
      height: 40px;

      input {
        min-height: 40px;
        height: 40px;
      }

    }
  }
}


.editor {
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-height: 314px;
  width: 100%;
  display: flex;

  :global {
    .rdw-editor-toolbar {
      min-height: 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background: #2F2F2F;
      border: none;
    }

    .public-DraftStyleDefault-block {
      margin: 0;
    }

    .postEditorContainer {
      min-height: 314px;
      max-height: 400px;
    }

    .postEditor {
      max-height: 340px;
      padding: 12px;
      @include scrollBar;

      overflow-y: auto;

      a {
        color: #90caf9;
      }

    }

    .rdw-link-modal-target-option {
      display: none;
    }

    .rdw-option-wrapper {
      width: 24px;
      height: 24px;
      min-width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 1px solid transparent;
      background: #2F2F2F;
      transition: 0.2s;

      path: {
        stroke: #8D8D8D
      }

      &.rdw-option-active,
      &:hover{
        border: 1px solid #525252;
        background: #202020;
        border-radius: 4px;
        outline: none;
        box-shadow: none;
      }
    }

    .rdw-link-modal {
      background: #2F2F2F;
      border-color: #525252;
      outline: none;
      box-shadow: none;
      left: -100px;
    }

    .rdw-link-modal-input {
      background: #202020;
      border-color: #525252;
      color: #fff;
    }

    .rdw-link-modal-btn {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      background-color: #90caf9;
      border: none;
      border-radius: 8px;

    }
  }
}



.button {
  min-width: 85px!important;
}
