.wrapper {
  display: flex;
  gap: 24px;
  padding: 0 0 16px;
}

.row   {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}


.btn {
  padding: 0;
  min-width: 24px!important;
  width: 24px!important;
  height: 24px!important;

  svg {
    min-width: 24px!important;
    width: 24px!important;
    height: 24px!important;

  }
}

.iconBtn {
  padding: 0;
  width: 27px;
  height: 27px;
}

