.wrapper {
  min-height: 151px;
  width: 100%;
  border-width: 1px;
  border-style: dashed;
  border-color: #40454A;
  border-radius: 8px;
  cursor:  pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 24px;

  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}

.images {
  display: flex;
  width: 100%;
  padding: 12px 0;
  gap: 8px;
  flex-wrap: wrap;

  > div {
    width: 42px;
    height: 42px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
