.wrapper {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  justify-content: flex-end;

  :global {
    .MuiButtonBase-root {
      padding: 0!important;
      margin: 0!important;
      min-width: 40px!important;
      width: 40px!important;
      height: 28px;
    }
  }
}
