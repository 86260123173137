.itemWrapper {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 12px!important;
  width: 100%;
}

.auto {
  margin-left: auto;
  flex-shrink: 0;
  width: 32px;
  padding: 0!important;
}

.box {
  display: flex;
  padding: 12px 0;
  gap: 8px;
  align-items: center;
}

.relative {
  width: 100%;
  height: 100%;
  position: relative;

  >div {
    >div {

      &:nth-child(4) {
        margin-top: 0;
      }
    }
  }
}

.noImage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }
}

.container {
  display: flex;
  width: 100%;
  gap: 16px;
}

.gallery {
  position: relative;
  flex: 0 0 211px;
  padding-top: 12px;
  >div {
    >div {

      &:nth-child(4) {
        margin-top: 0;
      }
    }
  }
}

.rightCol {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  column-gap: 8px;
  position: relative;
}


.footer {
  display: flex;
  width: 100%;
  position: relative;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 12px 16px;
  text-align: left;
  flex-grow: 1;

  &.large {
    width: 100%;
    max-width: 284px;
  }
}

.divider {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  display: flex;
  height: auto!important;
}

.imageItem {
  height: 128px;
}

.textRight {
  align-items: flex-end;
  text-align: right;
}

.row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  text-align: left;
}

.dividerBottom {
  display: flex;
  position: absolute;
  left: 0;
  right: -12px;
  top: 0;
  height: 1px!important;
}


.radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin: auto auto 0!important;

  >* {
    font-size: 12px!important;
  }

  :global {
    .MuiFormControlLabel-root {
      width: fit-content!important;
    }

    .MuiRadio-root {
      padding: 0!important;

    }
  }
}
