.noData {
  padding: 32px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;

  >svg {
    margin-bottom: 16px;
  }
}

.noData {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
