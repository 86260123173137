.wrapper {
    width: 100%;
    height: 172px;
    overflow: hidden;
    border-radius: 8px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.box {
 :global {
    .caruoselIndicator {
      width: 4px!important;
      height: 4px!important;

      svg: {
        width: 4px!important;
        height: 4px!important;
      }
    }
  }
}

.item {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
}

