.input {
  input {
    &::placeholder,
    &:disabled {
      color: inherit !important;
      opacity: 1 !important;
    }
  }
}

.circle {
  position: absolute;
  right: 4px;
  top: 4px;
}
