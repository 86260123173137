.wrapper {
  background: rgba(18,18,18,0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    .yarl__container {
      max-height: 835px;
      height: 100vh;
      top: 50% !important;
      left: 50%!important;
      transform: translate(-50%, -50%);
      background-color: transparent!important;
      box-shadow: none !important;
    }

    .yarl__counter {
      top: 0!important;
      left: 16px!important;
      bottom: unset!important;
      background: #121212;
      border: 1px solid #FFFFFF;
      font-family: Inter;
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      text-align: left;
      padding: 0 4px;
      border-radius: 4px;
    }

    .yarl__slide {
      height: 100%;
      img {
        border-radius: 8px;
        height: 100%;
      }
    }

    .yarl__button {
      transition: 0.3s all;
      filter: none!important;

      &:hover {
        opacity: 0.8;
      }
    }

    .yarl__navigation_next {
      right: 16px!important;
      filter: none!important;
    }

    .yarl__navigation_prev {
      left: 16px!important;
      filter: none!important;
    }



  }
}
