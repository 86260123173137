.wrapper {
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
}
