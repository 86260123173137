.assigne {
  font-weight: 400;
  font-size: 10px!important;
  line-height: 100%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 0;
  text-align: right;
  padding: 6px 0;
  margin: 2px 0 4px;

  &:hover {
    text-decoration: none;
  }
}
