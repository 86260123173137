.row {
  width: 100%;
  display: flex;
  justify-content: space-between;

  :global {
    .MuiButtonBase-root {
      padding: 0!important;
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
  }
}

.relative {
  position: relative;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 48px;

  :global {
    .MuiButtonBase-root {
      border-radius: 4px!important;
    }
  }
}

.input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px ;
  width: 264px;
  min-height: 48px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  position: absolute;
  left: 50%;
  top: -60px;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 0;
  visibility: hidden;

  &.isOpen {
    opacity: 1;
    visibility: visible;
  }

  :global {
    .MuiInputBase-input,
    .MuiInputBase-root {
      width: 100%;
      height: 32px;
      min-height: 32px;
      border-width: 1px;
      border-style: solid;
      outline: none!important;
      border-radius: 4px!important;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &:not(.Mui-focused) {
        border-color: transparent;
        border-width: 1px;
      }

      &:before,
      &:after {
        display: none;
      }

      &::placeholder {
        color: #8D8D8D
      }
    }
    .MuiButtonBase-root {
      width: 37px!important;
      height: 30px!important;
      min-width: 37px!important;
      font-size: 14px;
      font-weight: 600;
      line-height: 19.6px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-top: 2px;
    }
  }
}


.flex {
  display: flex;
  gap: 8px;
}
.wrap {
  flex-wrap: wrap;
}
