.medium {
  :global {
    .MuiInputBase-root {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      min-height: 40px;
      height: 40px;

      input {
        box-sizing: border-box;
        min-height: 40px;
        height: 40px;
        padding: 5px 10px;
      }

    }
  }
}

.loading {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  position: absolute;
}

em {
  opacity: 1;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.5);
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}
