@mixin scrollBar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    transform: translateX(12px);
  }

  &::-webkit-scrollbar-track {
    background: #525252;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #202020;
    border-radius: 10px;
    border: 2px solid #525252;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #323232;
  }
}
