@import "../../../styles/mixins";
.medium {
  :global {
    .MuiInputBase-root {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      min-height: 40px;
      width: 100%;


      input {
        box-sizing: border-box;
        min-height: 40px;
        height: 40px;
        padding: 5px 10px;
      }

    }

    .MuiAutocomplete-root {
      width: 100% !important;
      padding: 0 !important;
    }

    .MuiOutlinedInput-root {
      padding: 0 5px!important;
    }

    .MuiChip-root {
      height: 24px;
    }
  }
}

.disabled {
  opacity: 0.6;
}
