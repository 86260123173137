.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.noWrap {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.row   {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.wrapper {
  width: 100%;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.btn {
  padding: 0;
  min-width: 24px!important;
  width: 24px!important;
  height: 24px!important;

  svg {
    min-width: 24px!important;
    width: 24px!important;
    height: 24px!important;

  }
}

.iconBtn {
  padding: 0;
  width: 27px;
  height: 27px;
}

.link2:hover svg {
  fill: #42a5f5;
}
