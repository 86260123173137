.btn {
  text-transform: none!important;
  font-weight: 700;
}

.row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px!important;
  min-width: 40px!important;
  height: 30px;
  padding: 0!important;
}

.medium {
  :global {
    .MuiButtonBase-root {
      width: 40px!important;
      height: 40px!important;
    }
  }
}

.small {

}

