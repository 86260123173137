.dialogWrapper {

  form {
    width: 396px;
    padding: 24px;
    border-radius: 12px;
    overflow: hidden;
  }

  :global {
    .MuiPaper-rounded {
      border-radius: 12px!important;
    }
  }
}

.btns {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.comment {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.row {
  width: 100%;
  display: flex;
  gap: 12px;
}
