.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;
}

.relative {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.label {
  width: 100%;
  min-height: 30px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-style: solid;
  border-width: 1px;
  position: relative;
  padding: 8px;
}

.dropdown {
  width: 100%;
  min-height: 30px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-style: solid;
  border-width: 1px;
  position: relative;
  cursor: pointer;

  :global {
    .MuiInputBase-root {
      font-size: 10px!important;
      font-weight: 400;
      line-height: 16px;
      min-height: 30px;
      height: 30px;
      width: 100%;
      background: transparent;
      border-radius: 4px!important;
      color: inherit!important;

      input {
        box-sizing: border-box;
        min-height: 30px;
        height: 30px;
        background: transparent;
        padding: 0!important;
        border-radius: 0!important;
        color: inherit!important;
        font-size: 10px!important;
      }
    }
  }
}


