.opacity {
  opacity: 0.3;
}

.wrapper {
  width: 16px;
  height: 20px;

  div {
    width: 16px;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(2) {
      transform: rotate(180deg);
    }
  }
}