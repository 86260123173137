
.link {
  padding: 0;
  min-width: 27px;
  height: 27px;
}

.link:hover svg {
  fill: #42a5f5;
}

.button {
  font-family: Inter;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  text-transform: none!important;

  svg {
    width: 20px;
    height: 20px;
  }
}

.rotate {
  svg {
    transform: rotate(-180deg)!important;
  }
}

.adress {
  font-family: Inter;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0%;
  color: #8D8D8D;

  svg {
    color: #54B577;
  }

  li {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 5px 5px 25px;
    border-radius: 5px;
    position: relative;
    width: fit-content;

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      left: 10px;
      background: #525252;
    }

    button {
      padding: 0;
    }

    &.main {
      background: #2F2F2F;
    }
  }
}

.linkCopy {
  display: flex;
  align-items: center;
  color: #8D8D8D;
  text-decoration: none;
  margin-left: 10px;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }

  svg {
    color: #54B577;
    margin-left: 5px;
  }
}
