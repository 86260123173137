.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 24px 0;
  position: relative;

  &:after {
    content: '';
    width: 100vw;
    height: 1px;
    position: absolute;
    left: -24px;
    bottom: 0;
  }
}

.divider {
  width: 100vw;
  height: 1px;
  position: absolute;
  left: -24px;
  bottom: 0;
}

.mark {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #4760BB;
  position: absolute;
  right: -2px;
  top: -2px;
}
