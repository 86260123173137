.noImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;

  svg {
    width: 100px;
    height: 100px;
  }
}
