.apartment-fields-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 0;
    margin-bottom: 2.5rem;
}

.apartment-field-item {
  min-width: 25%;
  width: 25%;
}

.caruoselIndicator {
  margin-top: -10px;
  svg {
    width: 4px!important;
    height: 4px!important;
  }
}

body * {
  font-family: "Inter", serif!important;
  font-optical-sizing: auto!important;
  font-style: normal!important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  padding: 10px;
}
