.addContact {
  height: 24px;
  border-radius: 8px;
  position: absolute!important;
  left: 50%;
  bottom: -12px!important;
  transform: translateX(-50%);
  text-transform: none!important;
}

.button {
  width: 20px;
  height: 20px;

  :global {
    .MuiButtonBase-root {
      padding: 12px!important;
    }
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  position: absolute!important;
  right: -10px;
  top: 50px;
  z-index: 1;
}

.hidden {
  display: none;
}

.linked {
  position: absolute;
  right: 8px;
  top: 48px;
  background: #2C2C2C;
  border-radius: 4px;

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    &~.checked {
      display: none;
    }

    &~.unchecked {
      display: flex;
    }
    &:checked {

      &~.checked {
        display: flex;
      }
      &~.unchecked {
        display: none;
      }
    }
  }

  :global {
    .MuiButtonBase-root {
      padding: 4px!important;
      border-radius: 4px!important;
    }
  }
}

.relative {
  position: relative;
}

.rightPadding {
  padding-right: 32px;
}

