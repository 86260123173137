@import "../../../../styles/mixins";

.top  {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #2F2F2F;
  position: relative;
  padding: 14px 24px;

  :global {
    .MuiButtonBase-root {
      padding: 0!important;
    }
  }
}

.btn {
  min-width: 117px!important;
}


.wrapper {
  width:100%;
  max-width: 1108px;

  margin: auto;
  border-radius: 12px;

  :global {
    .MuiPaper-root {
      background-image: none!important;
      width: 100%;
      max-width: 100%;
      border-radius: 12px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
}

.inner {
  padding: 14px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.input {
  width: 100%;
  max-width: 284px;
  margin-bottom: 12px;
}

.form {
  width: 100%;
  max-width: 100% !important;
  border-radius: 12px;
  overflow: hidden;
  margin: auto;
  height: 80vh;
}


.results {
  height: calc(80vh - 253px);
  width: calc(100% + 20px);
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px!important;
  margin-right: -20px;
  @include scrollBar;

  :global {
    .MuiFormGroup-root {
      display: flex;
      align-items: flex-start;
    }
  }
}


.bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
}
