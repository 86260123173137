.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
  touch-action: none;
}
