.wrapper {
  display: flex;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;

  :global {
    .MuiButton-root {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 119px;
      min-height: 32px;
      border-radius: 4px!important;
      padding: 4px!important;
      text-transform: unset;
    }
  }
}

.btn {
  width: 40px;
  min-width: 40px!important;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0!important;
  margin-right: 12px;
  border-radius: 4px!important;
  color: inherit;
}

.container {
  display: flex;
  justify-content: flex-start;
}
