.wrapper {
  :global {
    .MuiSelect-select {
      display: flex;
      align-items: center;
      padding: 0 24px 0 15px !important;
      height: 48px !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      max-width: 100%;
      width: 125px;
      box-sizing: border-box;
    }

    .MuiInputBase-root {
      border-radius: 8px 0 0 8px !important;
      padding: 0 !important;
      margin-left: -13px;
      height: 100%;

      svg:first-child {
        display: none;
      }

      &:before,
      &:after {
        border: none !important;
        display: none !important;
      }
    }

    svg {
      right: 0 !important;
    }
  }
}

.medium {
  :global {
    .MuiInputBase-root {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      min-height: 40px;
      height: 40px;
      padding-left: 0;

      input {
        box-sizing: border-box;
        min-height: 40px;
        height: 40px;
        padding: 5px 10px;
      }
    }
  }
}

.large {
  :global {
    .MuiOutlinedInput-root {
      max-height: 48px;
    }

    input {
      padding: 12px 6px;
    }

    input:-webkit-autofill {
      background-color: transparent !important;
      color: inherit !important;
      -webkit-box-shadow: 0 0 0 1000px #121212 inset !important;
      border-radius: 0 !important;
    }
  }
}

.searchButton {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 24px;
  width: 24px;

  svg {
    path {
      transition: stroke 0.3s;
      stroke: #8D8D8D;
    }
  }

  &:hover {
    svg {
      path {
        stroke: #90caf9;
      }
    }
  }
}

.divider {
  width: 1px;
}

.closeButton {
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #292929;

  svg {
    width: 16px;
    height: 16px;

    path {
      transition: stroke 0.3s;
      stroke: #8D8D8D;
    }
  }

  &:hover {
    svg {
      path {
        stroke: #90caf9;
      }
    }
  }
}
