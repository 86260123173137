.addContact {
  height: 24px;
  border-radius: 8px;
  position: absolute!important;
  left: 50%;
  bottom: 12px!important;
  transform: translateX(-50%);
  text-transform: none!important;
}

.button {
  width: 20px;
  height: 20px;

  position: absolute!important;
  right: -10px;
  top: 50px;

  :global {
    .MuiButtonBase-root {
      padding: 12px!important;
    }
  }
}

.boxCard {
  padding: 16px;
  position: relative;
  gap: 16px;
  border-radius: 12px;
  margin-bottom: 24px;
}

.remove {
  position: absolute!important;
  right: -10px;
  top: -10px;
  z-index: 1;
  padding: 0px!important;
}

.radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin: auto auto 0!important;

   >* {
     font-size: 12px!important;
   }

  :global {
    .MuiFormControlLabel-root {
      width: fit-content!important;
    }

    .MuiRadio-root {
      padding: 0!important;

    }
  }
}

.flex {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    svg {
      fill: #42a5f5;
    }
  }
}
