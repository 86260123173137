.wrapper {
  :global {
    .MuiInputBase-root {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      min-height: 40px;
      height: 40px;

      input {
        min-height: 40px;
        height: 40px;
      }

    }
  }
}
