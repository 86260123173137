.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.sidebarWrapper {
  display: flex;
  flex-direction: column;
  width: 280px;
  flex-shrink: 0;
  padding: 24px;
  height: 100%;
  position: relative;
  overflow: visible!important;
  border-radius: 0!important;
  z-index: 100;
}


