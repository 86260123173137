.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.col {
  display: flex;
  gap: 8px;
}

.item {
  display: flex;
  gap: 4px;
  padding-left: 20px;
  position: relative;

  &:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #525252;
    position: absolute;
    left: 4px;
    top: 7px;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }
}


.success {
  color: #54B577!important;
}

.warning {
  color: #E28035!important;
}

.error {
  color: #EC4747!important;
}

.default {
  color: #FFFFFF;
}

.label {
  height: 22px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
}

