.snackWrapper {
  padding: 8px;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
}

.barRow {
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    margin-left: auto;
    margin-right: 0;
    padding: 0;
  }
}
