.loading {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.hide {
  opacity: 0;
  position: absolute;
  touch-action: none;
  pointer-events: none;

  +.hide {
    position: static;
  }
}

.show {
  opacity: 1;
  position: static;
  touch-action: unset;
  pointer-events: unset;
  +.hide {
    position: absolute;
  }
}

.btn {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-width: 136px!important;
  min-height: 37px!important;
}

.form {
  position: relative;

  :global {
    .MuiChip-root {
      min-width: 100px;
    }
  }
}

.btnWrapper {
  display: flex;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 12px 34px;
  z-index: 99;
  box-shadow: 0px 4px 8px 0px #11131B59;
  transition: all 0.3s ease-in-out;
}

