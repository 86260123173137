.box {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  position: relative;
  gap: 16px;
  border-radius: 12px;

  >div {
    width: 100%;
    max-width: calc(100% / 3 - 11px);
  }
}

.boxCard {
  padding: 16px;
  position: relative;
  gap: 16px;
  border-radius: 12px;
}

.sceleton {
  position: absolute;
  left: 12px;
  top: 24px;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px 16px;

  >* {
    margin-bottom: 12px;
  }
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;

  :global {
    .MuiButtonBase-root {
      padding: 0!important;
      margin-right: 8px;
    }
  }
}

.inner {
  padding: 12px 0;
}

.deviderCol {
  margin: 0 -36px!important;
  width: calc(100% + 36px);
  max-width: calc(100% + 36px);
  position: relative;
  left: 12px;
}

.deviderInner {
  margin: 16px -32px 0!important;
  width: calc(100% + 32px);
  max-width: calc(100% + 32px);
  position: relative;
  left: 32px;
}


.deviderVertical {
  width: 1px;
  position: absolute;
  left: 12px;
  top: 24px;
  bottom: 0;
}

.hide {
  opacity: 0;
  position: absolute;
  touch-action: none;
  pointer-events: none;

  +.hide {
    position: static;
  }
}

.show {
  opacity: 1;
  position: static;
  touch-action: unset;
  pointer-events: unset;
  +.hide {
    position: absolute;
  }
}



