.wrapper {
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  position: relative;
  flex-wrap: wrap;
}

.btn {
  flex-shrink: 0;
  height: 40px!important;
  width: 40px!important;
  min-width: 40px!important;
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  text-decoration-skip-ink: none;
  text-transform: none!important;
  padding: 0!important;
}

.divider {


}

.inner {
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  position: relative;
  flex-wrap: wrap;
}

.inner,
.wrapper {
  &:before {
    content: '';
    width: 100vw;
    height: 1px;
    position: absolute;
    left: -24px;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: #2F2F2F;
  }
}

.shadow {
  width: 100vw;
  position: absolute;
  left: -24px;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
}

.dialogWrapper {

  form {
    width: 396px;
    padding: 24px;
    border-radius: 12px;
    overflow: hidden;
  }

  :global {
    .MuiPaper-rounded {
      border-radius: 12px!important;
    }
  }
}
