.wrapper {
  display: grid!important;
  grid-template-columns: repeat(auto-fit, minmax(80px, 0.5fr));
  gap: 12px;
  padding: 0;
  margin: 0!important;
  width: 100%!important;

  img {
    width: 100%;
    object-fit: cover;
  }

  :global {
    .MuiGrid-root {
      padding: 0!important;
    }
  }
}

.noImage {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.card {
  display: flex;
  margin: 6px;
  height: 203px;

  img {
    width: auto;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

.list {
  :global {
    [data-react-beautiful-dnd-draggable] {
      //width: calc(100% / 3 - 3px);
      width: 100%;

      img {
        height: 203px;
      }
    }
  }
}

.listRow {
  display: flex;
  flex-wrap: wrap;
}
