.row  {
  display: flex;
  width: 155px;
  height: 37px;
  background: #121212;
  border-radius: 8px;

  :global {
    .MuiTypography-caption {
      margin: 0;
      height: 0;
    }
  }
}
