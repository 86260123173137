.dialogWrapper {

  form {
    width: 396px;
    padding: 24px;
    border-radius: 12px;
    overflow: hidden;
  }

  :global {
    .MuiPaper-rounded {
      border-radius: 12px!important;
    }
  }
}

.btns {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.comment {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.checkbox {
  padding: 0;
  margin-right: 12px;

  :global {
    .MuiCheckbox-root {
      padding: 0!important;
    }
  }
}

.row {
  :global {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
  }
}

.button {
  min-width: 85px!important;
}

.radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin: auto auto 0!important;

  >* {
    font-size: 12px!important;
  }

  :global {
    .MuiFormControlLabel-root {
      width: fit-content!important;
    }

    .MuiRadio-root {
      padding: 0!important;

    }
  }
}
