.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 24px 0;
  position: relative;

  &:after {
    content: '';
    width: 100vw;
    height: 1px;
    position: absolute;
    left: -24px;
    bottom: 0;
  }
}

.divider {
  width: 100vw;
  height: 1px;
  position: absolute;
  left: -24px;
  bottom: 0;
}

.btn {
  display: flex;
  gap: 4px;
  min-height: 48px ;
  font-size: 16px!important;
  font-weight: 600!important;
  line-height: 19.36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: none!important;
}
