.wrapper {
  :global {
    .MuiSelect-select {
      display: flex;
      align-items: center;
      padding: 0 24px 0 15px!important;
      height: 38px;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
    .MuiInputBase-root {
      border-radius: 8px 0 0 8px!important;
      padding: 0!important;
      margin-left: -10px;

      &:before,
      &:after{
        border: none!important;
        display: none!important;
      }
    }

    svg {
      right: 0px!important;
    }
  }
}

.disabled {
  opacity: 0.6;
  overflow: hidden;
}

.medium {
  :global {
    .MuiInputBase-root {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      min-height: 40px;
      height: 40px;
      padding-left: 0;

      input {
        box-sizing: border-box;
        min-height: 40px;
        height: 40px;
        padding: 5px 10px;
      }

    }
  }
}

