.medium {
  width: 100%;
  :global {
    .MuiInputBase-root {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      min-height: 40px;
      height: 40px;

      input {
        box-sizing: border-box;
        min-height: 40px;
        height: 40px;
        padding: 5px 10px;
      }

    }
  }
}

.small {
  width: 100%;
  :global {
    .MuiInputBase-root {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.6px;
      min-height: 32px;
      height: 32px;

      input {
        box-sizing: border-box;
        min-height: 32px;
        height: 32px;
        padding: 5px 12px;
      }

    }
  }
}

.wrapper {
  :global {
    .MuiInputBase-root {
      flex-grow: 0;
      width: fit-content;
      border-radius:  0!important;
      border: none!important;
      outline: none!important;
      margin-right: -22px;

      input {
        width: 80px!important;
        min-width: unset!important;
        opacity: 0.5;
      }
    }
  }
}

