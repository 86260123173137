@import "../../../styles/mixins";

.wrapper {
  width: 100%;
  border-radius: 8px ;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  margin-bottom: 28px;

  td,
  th {
    border-right-width: 1px;
    border-right-style: solid;

    &:last-child {
      border-right: none;
    }
  }

  :global {
    .MuiTableSortLabel-icon {
      transform: none!important;
    }

    td.MuiTableCell-root {
      padding: 12px;
    }

    .MuiTableContainer-root {
      max-height: calc(100vh - 215px);
      overflow-y: auto;
      @include scrollBar;
    }

    *,
    .MuiTableCell-root {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &.MuiTableCell-head {
        font-weight: 600!important;
      }
    }

    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

}

.container {
  width: 100%;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: center;

  :global {
    .MuiButtonBase-root {
      width: 24px;
      height: 24px;
      max-width: 24px;
      min-width: 24px;
      text-align: center;
      padding: 0;
      margin: 0;
    }

    .Mui-selected {
      background: #626ADF;
      color: #FFFFFF;
    }
  }

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}
